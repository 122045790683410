import { inkAvatarTokens, inkFontTokens } from '@ds/tokens'
import { hexa } from '../../../../styles'
import type { IndexedColorStyles } from '../../../types'

const indexedBgColorPalette = [
  inkAvatarTokens.avatarBgColor1,
  inkAvatarTokens.avatarBgColor2,
  inkAvatarTokens.avatarBgColor3,
  inkAvatarTokens.avatarBgColor4,
  inkAvatarTokens.avatarBgColor5,
  inkAvatarTokens.avatarBgColor1Alt,
  inkAvatarTokens.avatarBgColor2Alt,
  inkAvatarTokens.avatarBgColor3Alt,
  inkAvatarTokens.avatarBgColor4Alt,
  inkAvatarTokens.avatarBgColor5Alt,
] as const

const indexedColorPalette = [
  inkFontTokens.fontAvatarColor1,
  inkFontTokens.fontAvatarColor2,
  inkFontTokens.fontAvatarColor3,
  inkFontTokens.fontAvatarColor4,
  inkFontTokens.fontAvatarColor5,
  inkFontTokens.fontAvatarColor1Alt,
  inkFontTokens.fontAvatarColor2Alt,
  inkFontTokens.fontAvatarColor3Alt,
  inkFontTokens.fontAvatarColor4Alt,
  inkFontTokens.fontAvatarColor5Alt,
] as const

const NUM_COLORS = Object.keys(indexedColorPalette).length

/**
 * Returns a color object given an index into a list, often used to provide a
 * repeating sequence of colors to apply to recipients.
 *
 * @param {number} index - A zero-based index. Defaults to 0
 *
 * @returns {Object} colorObject
 * @returns {string} colorObject.solid - The hex string of the color, e.g. '#1e1e1e'.
 * @returns {string} colorObject.transparent - A CSS rgba string of the color
 * with transparency, e.g. 'rgba(30, 30, 30, 0.75)'.
 * @returns {string} colorObject.border - Aa dark border that can be used
 * to maintain accessibility requirements.
 */
const indexedColor = (index = 0): IndexedColorStyles => {
  const normalizedIndex = Math.max(0, index % NUM_COLORS)
  const bgColor = indexedBgColorPalette[normalizedIndex]
  const color = indexedColorPalette[normalizedIndex]

  return {
    solid: bgColor,
    transparent: hexa(bgColor, 0.75),
    border: bgColor,
    color,
  }
}

export default indexedColor
