import React from 'react'
import PropTypes from 'prop-types'
import { dataProps } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import { variant } from '../../../utilities'
import type { AvatarSize } from '../Avatar'
import styles from './styles'

const avatarSizes = [
  'xxlarge',
  'xlarge',
  'large',
  'medium',
  'small',
  'xsmall',
] as const

export interface AvatarBadgeProps {
  /**
   * The text to present to assistive devices in order to identify the AvatarBadge.
   */
  accessibilityText: string
  /**
   * The size of the Avatar Badge.
   */
  size?: AvatarSize
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function AvatarBadge({
  accessibilityText,
  ...restProps
}: AvatarBadgeProps) {
  const sx = useThemeStyles(styles)

  const badgeStyles = [
    sx.default.badge,
    restProps.size && sx[variant('size', restProps.size)].badge,
  ]

  const badgeText = <span css={sx.hidden}>{accessibilityText}</span>

  return (
    <span {...dataProps(restProps)} css={badgeStyles}>
      {badgeText}
    </span>
  )
}

AvatarBadge.propTypes = {
  accessibilityText: PropTypes.string.isRequired,
  size: PropTypes.oneOf(avatarSizes),
}

AvatarBadge.displayName = 'Avatar.Badge'
