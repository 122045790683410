/**
 * Utilities for use in portal components (ModalPortal, OverlayPortal)
 */

export const DATA_ARIA_ACTIVE = 'data-aria-active'

/**
 * Add aria-hidden attr to portal siblings
 */
export function addAriaHiddenAttributes(elements: Element[]): void {
  elements.forEach((el) => el.setAttribute('aria-hidden', 'true'))
}

/**
 * Remove aria-hidden attr from portal siblings
 */
export function removeAriaHiddenAttributes(elements: Element[]): void {
  elements.forEach((el) => el.removeAttribute('aria-hidden'))
}

/**
 * Add inert attr to portal siblings
 */
export function addInertAttributes(elements: Element[]): void {
  elements.forEach((el) => el.setAttribute('inert', 'true'))
}

/**
 * Remove inert attr from portal siblings
 */
export function removeInertAttributes(elements: Element[]): void {
  elements.forEach((el) => el.removeAttribute('inert'))
}

/**
 * Used to filter portal sibling elements that already have aria-hidden
 */
export function isNotAriaHidden(el: Element): boolean {
  return el.getAttribute('aria-hidden') !== 'true'
}

export function isNotInert(element: Element) {
  return element.getAttribute('inert') !== 'true'
}

/**
 * Filter out all nodes that aren't html/svg elements (eg. text nodes or html comment nodes)
 */
export function isElement(el: Node): el is Element {
  return el instanceof Element
}

export function isNotAriaActive(element: Element) {
  return element.getAttribute(DATA_ARIA_ACTIVE) !== 'true'
}

/**
 * Get portal siblings that are valid html/svg elements. Used to add/remove aria-hidden
 */
export function getPortalSiblings(portalContainerNode: Node): Element[] {
  if (portalContainerNode.childNodes.length && portalContainerNode.parentNode) {
    const childNodes = Array.from(portalContainerNode.parentNode.childNodes)
    const portalIndex = childNodes.indexOf(portalContainerNode as ChildNode)

    return childNodes
      .filter((_node, index) => index < portalIndex)
      .filter(isElement)
      .filter(isNotInert)
      .filter(isNotAriaActive)
      .filter(isNotAriaHidden)
  }
  return []
}
