import type { MenuUIProps } from '..'

export default (config: { props: Partial<MenuUIProps> }) => {
  const { props } = config

  return {
    menuUIBackground: {
      border: !props.positionStatic ? '1px solid #cccccc' : undefined,
    },

    menuUIMenuItems: {
      border: props.positionStatic ? '1px solid #cccccc' : undefined,
    },
  } as const
}
