import { hexa } from '../../../../styles'
import type { IndexedColorStyles } from '../../../types'
import { recipientColorPalette } from '../colors'

const NUM_RECIPIENT_COLORS = Object.keys(recipientColorPalette).length

/**
 * Returns a color object given an index into a list, often used to provide a
 * repeating sequence of colors to apply to recipients.
 *
 * @param {number} index - A zero-based index. Defaults to 0
 *
 * @returns {Object} colorObject
 * @returns {string} colorObject.solid - The hex string of the color, e.g. '#1e1e1e'.
 * @returns {string} colorObject.transparent - A CSS rgba string of the color
 * with transparency, e.g. 'rgba(30, 30, 30, 0.75)'.
 * @returns {string} colorObject.border - Aa dark border that can be used
 * to maintain accessibility requirements.
 */
const indexedColor = (index = 0): IndexedColorStyles => {
  const normalizedIndex = Math.max(0, index % NUM_RECIPIENT_COLORS)
  const key = `recipient${normalizedIndex}`
  // @ts-expect-error
  const color = recipientColorPalette[key]

  return {
    solid: color.Normal,
    transparent: hexa(color.Normal, 0.75),
    border: color.Dark2,
  }
}

export default indexedColor
