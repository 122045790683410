import { CreateFocusCss } from '../../../styles'
import type { Tokens } from '../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        display: 'inline-block',
        position: 'relative',

        '& > a:hover': {
          boxShadow: `0 0 0 4px ${tokens.formControlHighlightBgColorDefault}`,
        },

        '& > a:active': {
          boxShadow: `0 0 0 4px ${tokens.itemBgColorActive} !important`,
        },

        '& > a': CreateFocusCss(tokens.focusOuter),
      },

      avatar: {
        ...tokens.fontAvatarGroup,
        alignItems: 'center',
        backgroundColor: tokens.avatarBgColorDefault,
        border: 'none',
        borderRadius: '50%',
        color: tokens.fontColorDefault,
        display: 'inline-flex',
        flexShrink: 0,
        justifyContent: 'center',
        overflow: 'hidden',
        padding: 0,
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        textTransform: 'uppercase',
        transition: 'box-shadow 0.2s ease',
        verticalAlign: 'middle',
        wordBreak: 'keep-all',
      },

      badge: {
        lineHeight: '0px',
        bottom: '-2px',
        position: 'absolute',
        insetInlineEnd: '-2px',
      },

      shadow: {
        boxShadow: tokens.elevationLow,
      },
    },

    image: {
      avatar: {
        img: {
          maxWidth: '100%',
        },
      },
    },

    placeholder: {
      avatar: {
        backgroundColor: tokens.avatarBgColorPlaceholder,

        '& > svg': {
          borderRadius: '50%',
          fill: tokens.fontColorInverse,
          height: '100%',
          overflow: 'hidden',
          width: '100%',
        },
      },
    },

    withColorIndex: {
      avatar: {
        color: tokens.fontColorDefault,
        '&:hover': {
          color: tokens.fontColorDefault,
        },
      },
    },

    indexedColor: tokens.indexedColor,
  } as const
}
