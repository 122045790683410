/**
 * Utility to convert CSS hex color into an rgba() color
 */
export function hexa(hex: string, alpha: number) {
  let parsedHex = hex

  if (hex.length === 4) {
    const h1 = hex.slice(1, 2)
    const h2 = hex.slice(2, 3)
    const h3 = hex.slice(3, 4)
    parsedHex = `#${h1}${h1}${h2}${h2}${h3}${h3}`
  }

  const r = parseInt(parsedHex.slice(1, 3), 16)
  const g = parseInt(parsedHex.slice(3, 5), 16)
  const b = parseInt(parsedHex.slice(5, 7), 16)

  if (alpha >= 0) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }

  return `rgb(${r}, ${g}, ${b})`
}
