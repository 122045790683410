import React, { useEffect } from "react";
import { Button } from "@ds/ui";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RecipientsEntryList from "./RecipientsEntryList";
import actions from "../actions";
import {
  getAccountId,
  getClientTransactionId,
  getDssSettings,
  getNextAction,
  getRecipients,
  getIsCollapsed,
  getIsSubmitButtonDisabled,
  getSuccessfullyActivated,
  getStatus
} from "../reducers/powerform";
import {
  getPowerFormId,
  getServer,
  getQuery,
  getHdnStartValues,
  getHdnPersistOriginalValues,
  getApiErrors,
  getHasAlreadyPosted
} from "../reducers/context";
import StickyWrapper from "../olive/StickyWrapper";
import { withTranslations } from "../lib/TranslationProvider";
import * as translations from '../lib/i18n/en.json';
import he from "he"; // html entities: he.decode('foo &copy; bar &ne; baz &#x1D306; qux') → 'foo © bar ≠ baz 𝌆 qux'
import { getQueryStringValue } from "../sagas";

const Panel = ({ children }) => <div className="panel">{children}</div>;

const Instructions = withTranslations(({ t, dsih }) => {
  // compare instructions to English string
  const decodedInstructions = he.decode(t("Instructions"));
  const useDefault = decodedInstructions === translations["form.instructions_1"];

  return (
    dsih ? (
      <>
        <h1
          data-qa="dialog-title"
          className="m-bottom-x-small dialog-title"
          dangerouslySetInnerHTML={{__html: t("DialogTitle")}}>
        </h1>
        <p
          className="powerform-dialog-instructions"
          data-qa="powerform-dialog-instructions"
          dangerouslySetInnerHTML={{__html:  useDefault ? he.decode(t("form.instructions_1")) : decodedInstructions}}
        >
        </p>
        <p data-qa="dialog-name-and-email" dangerouslySetInnerHTML={{__html: t("EnterNameAndEmail")}}>
        </p>
      </>
    ) : (
      <>
        <h1 data-qa="dialog-title" className="m-bottom-x-small dialog-title">{t("DialogTitle")}</h1>
        <p
          className="powerform-dialog-instructions"
          data-qa="powerform-dialog-instructions"
        >
          { useDefault ? he.decode(t("form.instructions_1", dsih)) : decodedInstructions}
        </p>
        <p data-qa="dialog-name-and-email">{t("EnterNameAndEmail")}</p>
      </>
    )
  );
});

export const PowerformsSubmissionForm = ({
  submitPowerform,
  recipients,
  server,
  powerformId,
  query,
  t,
  isCollapsed,
  isSubmitButtonDisabled,
  hdnStartValues,
  hdnPersistOriginalValues,
  apiError,
  EnablePowerformsV2_UI_Feature2,
  EnablePowerformsV2_UI_Feature3,
  FRM_1438_EnablePowerFormsRestAPI,
  successfullyActivated,
  status,
  nextAction,
  clientTransactionId,
  hasAlreadyPosted,
  accountId
}) => {
  const trimmedRecipients = recipients.map(r => {
    // We don't want to encode spaces, so split string on spaces and encode each token
    if (r.role) {
      const role = r.role
        .split(" ")
        .map(encodeURIComponent)
        .join(" ");
      return {
        ...r,
        name: r.name.trim(),
        role
      }
    }
    return { ...r, name: r.name.trim() }
  })
  
  const onSubmit = e => {    
    e.preventDefault();
    if (!hasAlreadyPosted) {
      submitPowerform(
        server,
        trimmedRecipients,
        powerformId,
        query,
        hdnStartValues,
        hdnPersistOriginalValues,
        successfullyActivated,
        EnablePowerformsV2_UI_Feature3,
        clientTransactionId,
        FRM_1438_EnablePowerFormsRestAPI,
        false,
        accountId
      );
    }
  };

  if (isCollapsed) {
    return null;
  }

  useEffect(() => {
    if (!apiError && recipients[0] && recipients[0].email && recipients[0].name && !hasAlreadyPosted) {
      if (nextAction === "EmailSent" || (status && status === "SentEmail")) {
        return;
      }
      if (successfullyActivated && nextAction === "ActivationRequired") {
        return;
      }
      submitPowerform(
        server,
        trimmedRecipients,
        powerformId,
        query,
        hdnStartValues,
        hdnPersistOriginalValues,
        successfullyActivated,
        EnablePowerformsV2_UI_Feature3,
        clientTransactionId,
        FRM_1438_EnablePowerFormsRestAPI,
        false,
        accountId
      );
    }
  }, [apiError]);

  useEffect(() => {
    document.title = t("DialogTitle") + " | Docusign";
  }, []);

  return (
    <form className="content_main" onSubmit={onSubmit}>
      <Panel>
        <Instructions dsih={EnablePowerformsV2_UI_Feature2} />
        <RecipientsEntryList dsih={EnablePowerformsV2_UI_Feature2} />
      </Panel>
      <StickyWrapper location="bottom" full="full" backgroundColor="#f4f4f4">
        <Button
          kind="primary"
          size="large"
          text={t("BeginSigningButtonText")}
          onClick={onSubmit}
          data-qa="dialog-submit"
          disabled={isSubmitButtonDisabled}
        />
      </StickyWrapper>
    </form>
  );
};

const TranslatedPowerformsSubmissionForm = withTranslations(
  PowerformsSubmissionForm
);

const connectActions = dispatch =>
  bindActionCreators(
    {
      submitPowerform: actions.submitPowerform
    },
    dispatch
  );

const connectProps = state => ({
  server: getServer(state.context),
  nextAction: getNextAction(state.powerform),
  powerformId: getPowerFormId(state.context),
  recipients: getRecipients(state.powerform),
  query: getQuery(state.context),
  isCollapsed: getIsCollapsed(state.powerform),
  isSubmitButtonDisabled: getIsSubmitButtonDisabled(state.powerform),
  hdnStartValues: getHdnStartValues(state.context),
  hdnPersistOriginalValues: getHdnPersistOriginalValues(state.context),
  apiError: getApiErrors(state.context),
  successfullyActivated: getSuccessfullyActivated(state.powerform),
  status: getStatus(state.powerform),
  ...getDssSettings(state.powerform),
  clientTransactionId: getClientTransactionId(state.powerform),
  hasAlreadyPosted: getHasAlreadyPosted(state.context),
  accountId: getAccountId(state.powerform)
}); 

export default connect(
  connectProps,
  connectActions
)(TranslatedPowerformsSubmissionForm);
