import fontFamilies from '../../../theming/docusign-themes/olive/fontFamilies'
import type { Tokens } from '../../../theming/types'
import type { ButtonKind, ButtonProps } from '..'
import type { KindStyles } from './ink'

export default (config: {
  props: Pick<ButtonProps, 'disabled' | 'kind'>
  tokens: Tokens
}) => {
  const { props, tokens } = config
  const kind: ButtonKind = props.kind!

  const kindStyles: KindStyles = {
    main: {
      color: {
        backgroundColor: tokens.button.main.defaultBackgroundColor,
        color: tokens.button.main.defaultTextColor,
      },
      hover: {
        backgroundColor: tokens.button.main.hoverBackgroundColor,
        color: tokens.button.main.defaultTextColor,
      },
      active: {
        backgroundColor: tokens.button.main.activeBackgroundColor,
      },
      disabled: {
        backgroundColor: tokens.button.main.defaultBackgroundColor,
        color: tokens.button.main.defaultTextColor,
      },
      seperator: {
        borderInlineStartColor: tokens.button.main.defaultTextColor,
      },
    },
    primary: {
      color: {
        backgroundColor: tokens.button.primary.defaultBackgroundColor,
        color: tokens.button.primary.defaultTextColor,
      },
      hover: {
        backgroundColor: tokens.button.primary.hoverBackgroundColor,
        color: tokens.button.primary.defaultTextColor,
      },
      active: {
        backgroundColor: tokens.button.primary.activeBackgroundColor,
      },
      disabled: {
        backgroundColor: tokens.button.primary.defaultBackgroundColor,
        color: tokens.button.primary.defaultTextColor,
      },
      seperator: {
        borderInlineStartColor: tokens.button.primary.defaultTextColor,
      },
    },
    secondary: {
      color: {
        backgroundColor: tokens.button.secondary.defaultBackgroundColor,
        borderColor: tokens.button.secondary.defaultBorderColor,
        color: tokens.button.secondary.defaultTextColor,
      },
      hover: {
        backgroundColor: tokens.button.secondary.hoverBackgroundColor,
        color: tokens.button.secondary.defaultTextColor,
      },
      active: {
        backgroundColor: tokens.button.secondary.activeBackgroundColor,
      },
      disabled: {
        color: tokens.button.secondary.defaultTextColor,
        backgroundColor: tokens.button.secondary.defaultBackgroundColor,
      },
      icon: {
        fill: tokens.iconColorDefault,
      },
      seperator: {
        borderInlineStartColor: tokens.button.secondary.defaultTextColor,
      },
    },
    tertiary: {
      color: {
        backgroundColor: tokens.button.tertiary.defaultBackgroundColor,
        color: tokens.button.tertiary.defaultTextColor,
      },
      hover: {
        backgroundColor: tokens.button.tertiary.hoverBackgroundColor,
        color: tokens.button.tertiary.defaultTextColor,
      },
      active: {
        backgroundColor: tokens.button.tertiary.activeBackgroundColor,
      },
      disabled: {
        color: tokens.button.tertiary.defaultTextColor,
        backgroundColor: tokens.button.tertiary.defaultBackgroundColor,
      },
      icon: {
        fill: tokens.iconColorDefault,
      },
      seperator: {
        borderInlineStartColor: 'transparent',
      },
    },
    danger: {
      color: {
        backgroundColor: tokens.button.danger.defaultBackgroundColor,
        color: tokens.button.danger.defaultTextColor,
      },
      hover: {
        backgroundColor: tokens.button.danger.hoverBackgroundColor,
        color: tokens.button.danger.defaultTextColor,
      },
      active: {
        backgroundColor: tokens.button.danger.activeBackgroundColor,
      },
      disabled: {
        color: tokens.button.danger.defaultTextColor,
        backgroundColor: tokens.button.danger.defaultBackgroundColor,
      },
      seperator: {
        borderInlineStartColor: tokens.button.danger.defaultTextColor,
      },
    },
  }

  return {
    button: {
      default: {
        fontFamily: fontFamilies.MavenPro,
        letterSpacing: '0.66px',
        textTransform: 'uppercase',
        ...kindStyles[kind].color,
        ...(kindStyles[kind].icon && {
          '& svg': { ...kindStyles[kind].icon },
        }),

        '&:hover': { ...kindStyles[kind].hover },
        '&:active, &[aria-expanded="true"]': { ...kindStyles[kind].active },
        '&:disabled': {
          opacity: tokens.opacityDisabled,
          cursor: 'not-allowed',
          ...kindStyles[kind].disabled,
        },
      },

      active: { ...kindStyles[kind].active },

      // These styles are only used in FileInput Component
      disabled: {
        '&:hover': { ...kindStyles[kind].disabled },
      },

      // These styles are only used in ButtonGroup and the ComboButton components
      buttonSeparatorColor: { ...kindStyles[kind].seperator },

      sizeSmall: {
        fontSize: '11px',
        minHeight: '22px',
        minWidth: '22px',
        padding: '1px 10px',
      },

      sizeMedium: {
        fontSize: '12px',
        minHeight: tokens.ctaSizeMd,
        minWidth: tokens.ctaSizeMd,
        padding: '4px 14px',
      },

      sizeLarge: {
        fontSize: '13px',
        minHeight: '34px',
        minWidth: '34px',
        padding: '7px 18px',
      },

      sizeXlarge: {
        fontSize: '13px',
        minHeight: '40px',
        minWidth: '40px',
        padding: '10px 20px',
      },

      round: {
        borderRadius: '50%',
        boxShadow: tokens.elevationLow,
        height: '48px',
        minHeight: '48px',
        padding: 0,
        width: '48px',

        '&:hover': {
          boxShadow: tokens.elevationMedium,
        },
      },

      inverted: {
        primary: {
          backgroundColor: tokens.button.invertedBackgroundColor,
          borderColor: tokens.button.invertedBackgroundColor,
          color: tokens.button.invertedTextColor,

          '& svg': {
            fill: tokens.button.invertedTextColor,
          },

          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: tokens.button.invertedBackgroundColor,
            color: tokens.button.invertedBackgroundColor,

            '& svg': {
              fill: tokens.button.invertedBackgroundColor,
            },
          },

          '&:active': {
            opacity: 0.7,
          },

          '&:disabled, &:disabled:hover': {
            opacity: 0.3,
          },
        },
      },

      ...(!props.disabled && {
        tertiary: {
          default: {
            '&:hover': {
              color: tokens.ctaBgColorPrimaryHover,
              '& svg': {
                fill: tokens.ctaBgColorPrimaryHover,
              },
            },
          },
        },
      }),
    },

    icon: {
      beforeText: {
        marginInline: '-4px 6px',
      },

      afterText: {
        marginInline: '6px -4px',
      },
    },

    menuTrigger: {
      paddingInlineEnd: '6px',
    },

    caret: {
      marginInlineStart: '2px',
    },

    pill: {
      borderRadius: '20px',
    },
  } as const
}
