import type { Tokens } from '../../../theming/types'
import type { ScrimProps } from '..'
import { useIsInk } from '../../../theming'

export default (config: {
  props: Pick<ScrimProps, 'light'>
  tokens: Tokens
}) => {
  const { props, tokens } = config
  const isInk = useIsInk()
  const opacityLevel = isInk ? 0.9 : 0.5

  return {
    scrim: {
      backgroundColor: tokens.bgColorScrim,
      bottom: 0,
      display: 'block',
      insetInlineEnd: 0,
      insetInlineStart: 0,
      position: 'fixed',
      top: 0,
    },

    motionVariants: {
      enter: {
        opacity: [0, props.light ? 0.75 : opacityLevel],
        transition: {
          ease: 'harmonize',
          duration: 0.4,
        },
      },

      exit: {
        opacity: [props.light ? 0.75 : opacityLevel, 0],
        transition: {
          ease: 'harmonize',
          duration: 0.35,
        },
      },
    },
  } as const
}
