import { CreateButtonCss, isTouchScreen } from '../../../styles'
import type { Tokens } from '../../../theming/types'
import { breakpoints } from '../../../utilities'
import type { ButtonKind, ButtonProps } from '..'

export type KindStyles = {
  [key in ButtonKind]: {
    color: {
      backgroundColor: string
      borderColor?: string
      color: string
    }
    icon?: {
      fill: string
    }
    hover: {
      backgroundColor: string
      color?: string
    }
    active?: {
      backgroundColor: string
    }
    focus?: {
      outlineColor: string
    }
    disabled?: {
      color?: string
      backgroundColor: string
    }
    seperator?: {
      borderInlineStartColor: string
    }
  }
}

export default (config: {
  tokens: Tokens
  props: Pick<ButtonProps, 'animate' | 'loading' | 'kind'>
}) => {
  const { tokens, props } = config
  const kind: ButtonKind = props.kind!

  const sizeStyles = {
    minHeight: tokens.ctaSizeMd,
    minWidth: props.animate ? undefined : '80px',
    padding: props.animate ? '4px 8px' : '4px 15px',

    [isTouchScreen]: {
      minHeight: tokens.ctaSizeLg,
    },
  } as const

  const kindStyles: KindStyles = {
    main: {
      color: {
        backgroundColor: tokens.ctaBgColorPrimaryDefault,
        color: tokens.fontColorInverse,
      },
      hover: {
        backgroundColor: tokens.ctaBgColorPrimaryHover,
      },
      active: {
        backgroundColor: tokens.ctaBgColorPrimaryActive,
      },
      seperator: {
        borderInlineStartColor: tokens.fontColorInverse,
      },
    },
    primary: {
      color: {
        backgroundColor: tokens.ctaBgColorPrimaryDefault,
        color: tokens.fontColorInverse,
      },
      hover: {
        backgroundColor: tokens.ctaBgColorPrimaryHover,
        color: tokens.fontColorInverse,
      },
      active: {
        backgroundColor: tokens.ctaBgColorPrimaryActive,
      },
      seperator: {
        borderInlineStartColor: tokens.fontColorInverse,
      },
      disabled: {
        backgroundColor: tokens.ctaBgColorPrimaryDefault,
      },
    },
    secondary: {
      color: {
        backgroundColor: tokens.ctaBgColorSecondaryDefault,
        borderColor: tokens.ctaBorderColorSecondaryDefault,
        color: tokens.fontColorDefault,
      },
      hover: {
        backgroundColor: tokens.ctaBgColorSecondaryHover,
        color: tokens.fontColorDefault,
      },
      active: {
        backgroundColor: tokens.ctaBgColorSecondaryActive,
      },
      seperator: {
        borderInlineStartColor: tokens.fontColorDefault,
      },
      disabled: {
        backgroundColor: tokens.ctaBgColorSecondaryDefault,
      },
      icon: {
        fill: tokens.iconColorDefault,
      },
    },
    tertiary: {
      color: {
        backgroundColor: tokens.ctaBgColorTertiaryDefault,
        color: tokens.fontColorDefault,
      },
      hover: {
        backgroundColor: tokens.ctaBgColorTertiaryHover,
        color: tokens.fontColorDefault,
      },
      active: {
        backgroundColor: tokens.ctaBgColorTertiaryActive,
      },
      seperator: {
        borderInlineStartColor: 'transparent',
      },
      disabled: {
        backgroundColor: tokens.ctaBgColorTertiaryDefault,
      },
      icon: {
        fill: tokens.iconColorDefault,
      },
    },
    danger: {
      color: {
        backgroundColor: tokens.button.danger.defaultBackgroundColor,
        color: tokens.button.danger.defaultTextColor,
      },
      hover: {
        backgroundColor: tokens.button.danger.hoverBackgroundColor,
        color: tokens.button.danger.defaultTextColor,
      },
      active: {
        backgroundColor: tokens.button.danger.activeBackgroundColor,
      },
      seperator: {
        borderInlineStartColor: tokens.button.danger.defaultTextColor,
      },
      disabled: {
        backgroundColor: tokens.button.danger.defaultBackgroundColor,
      },
    },
  }

  return {
    button: {
      default: {
        letterSpacing: 'normal',
        textTransform: 'none',
        ...kindStyles[kind].color,
        // Secondary and Tertiary buttons have a different color for the start/endElement icons than the text color. Defaults to currentColor in base.ts.
        ...(kindStyles[kind].icon && {
          '& svg': { ...kindStyles[kind].icon },
        }),

        '&:hover': { ...kindStyles[kind].hover },
        '&:active, &[aria-expanded="true"]': { ...kindStyles[kind].active },
        '&:disabled': {
          opacity: tokens.opacityDisabled,
          cursor: 'not-allowed',
          ...kindStyles[kind].disabled,
        },
      },

      active: {
        ...kindStyles[kind].active,
      },

      // These styles are only used in FileInput Component
      disabled: {
        '&:hover': { ...kindStyles[kind].disabled },
      },

      // These styles are only used in ButtonGroup and the ComboButton components
      buttonSeparatorColor: { ...kindStyles[kind].seperator },

      sizeSmall: sizeStyles,
      sizeMedium: sizeStyles,
      sizeLarge: sizeStyles,
      sizeXlarge: sizeStyles,

      inverted: {
        primary: {
          ...CreateButtonCss(tokens.button.primary),

          '&:hover': {
            backgroundColor: tokens.dark.ctaBgColorPrimaryHover,
          },

          '&:active': {
            backgroundColor: tokens.dark.ctaBgColorPrimaryActive,
            transitionDuration: '0.15s',
          },
        },
      },
    },

    menuTrigger: {
      paddingInlineEnd: '11px',
    },

    caret: {
      marginInlineStart: '12px',
    },

    hideText: {
      minWidth: tokens.ctaSizeMd,
      paddingBlock: props.loading ? '7px' : undefined,
      paddingInline: props.loading ? '7px' : undefined,

      [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
        paddingBlock: '7px',
        paddingInline: '7px',
      },

      [isTouchScreen]: {
        minWidth: tokens.ctaSizeLg,
        paddingBlock: '11px',
        paddingInline: '11px',
      },
    },

    icon: {
      beforeText: {
        marginInline: '0 8px',
      },

      afterText: {
        marginInline: '8px 0',
      },

      hideText: {
        padding: '7px',

        [breakpoints.lessThan(breakpoints.Sizes.Large)]: {
          padding: 0,
        },
      },
    },

    iconButton: {
      default: {
        paddingBlock: '7px',
        paddingInline: '15px',

        [isTouchScreen]: {
          paddingBlock: '11px',
        },
      },

      beforeText: {
        paddingInlineStart: '11px',

        [isTouchScreen]: {
          paddingInlineStart: '15px',
        },
      },

      afterText: {
        paddingInlineEnd: '11px',

        [isTouchScreen]: {
          paddingInlineEnd: '15px',
        },
      },
    },

    pill: {
      borderRadius: '24px',
    },
  } as const
}
