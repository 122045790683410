import type { CobrandingTokens } from '../../../theming/types'
import type { ButtonProps } from '..'

type Kind = 'main' | 'primary'

type KindStyles = {
  [key in Kind]: {
    color?: {
      backgroundColor?: string
      color?: string
    }
    hover?: {
      backgroundColor?: string
    }
    active?: {
      backgroundColor?: string
    }
    disabled?: {
      backgroundColor?: string
    }
    icon?: {
      [key: string]: string
    }
  }
}

export default (config: {
  tokens: CobrandingTokens
  props: Pick<ButtonProps, 'disabled' | 'kind'>
}) => {
  const { tokens, props } = config
  const kind = props.kind as Kind

  const kindStyles: KindStyles = {
    main: {
      color: {
        backgroundColor: tokens.buttonMainBackgroundColor,
        color: tokens.buttonMainTextColor,
      },
      hover: {
        backgroundColor: tokens.buttonMainBackgroundHoverColor,
      },
      active: {
        backgroundColor: tokens.buttonMainBackgroundActiveColor,
      },
      disabled: {
        backgroundColor: tokens.buttonMainBackgroundColor,
      },
    },
    primary: {
      color: {
        backgroundColor: tokens.buttonPrimaryBackgroundColor,
        color: tokens.buttonPrimaryTextColor,
      },
      hover: {
        backgroundColor: tokens.buttonPrimaryBackgroundHoverColor,
      },
      active: {
        backgroundColor: tokens.buttonPrimaryBackgroundActiveColor,
      },
      disabled: {
        backgroundColor: tokens.buttonPrimaryBackgroundColor,
      },
    },
  }

  return {
    button: {
      ...(tokens.buttonMainBackgroundColor && {
        default: {
          ...kindStyles[kind]?.color,
          ...(kindStyles[kind]?.icon && {
            '& svg': { ...kindStyles[kind]?.icon },
          }),

          '&:hover': { ...kindStyles[kind]?.hover },
          '&:active, &[aria-expanded="true"]': { ...kindStyles[kind]?.active },
          '&:disabled': { ...kindStyles[kind]?.disabled },
        },
      }),
      ...(tokens.buttonPrimaryBackgroundColor && {
        default: {
          ...kindStyles[kind]?.color,
          ...(kindStyles[kind]?.icon && {
            '& svg': { ...kindStyles[kind]?.icon },
          }),

          '&:hover': { ...kindStyles[kind]?.hover },
          '&:active, &[aria-expanded="true"]': { ...kindStyles[kind]?.active },
          '&:disabled': { ...kindStyles[kind]?.disabled },
        },
      }),
    },
  } as const
}
