import { Tokens } from '../../../theming/types/index'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      wrap: {
        '& > a:hover': {
          boxShadow: '0 0 0 4px transparent',
        },

        '& > a:active': {
          boxShadow: '0 0 0 4px transparent !important',
        },
      },

      avatar: {
        fontFamily: tokens.fontBodyM.fontFamily,
        fontWeight: 600,
        lineHeight: 1.5,
      },
    },

    placeholder: {
      avatar: {
        color: tokens.fontColorInverse,

        '& > svg': {
          borderRadius: '50%',
          fill: tokens.iconColorInverse,
          height: '100%',
          overflow: 'hidden',
          width: '100%',
        },
      },
    },

    sizeXxlarge: {
      avatar: {
        fontSize: '22px',
        height: '96px',
        width: '96px',
      },
    },

    sizeXlarge: {
      avatar: {
        fontSize: '22px',
        height: '72px',
        width: '72px',
      },
    },

    sizeLarge: {
      avatar: {
        fontSize: '15px',
        height: '48px',
        width: '48px',
      },
    },

    sizeMedium: {
      avatar: {
        fontSize: '15px',
        height: '36px',
        width: '36px',
      },
    },

    sizeSmall: {
      avatar: {
        fontSize: '13px',
        height: '28px',
        width: '28px',
      },
    },

    sizeXsmall: {
      avatar: {
        fontSize: '10px',
        height: '20px',
        width: '20px',
      },
    },
  } as const
}
