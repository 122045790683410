import { CreateFocusCss, hexa } from '../../../../styles'
import type { CobrandingTokens } from '../../../../theming/types'

export default (config: { tokens: CobrandingTokens }) => {
  const { tokens } = config
  const hasCustomBackgroundColor = tokens.headerBackgroundColor
  const hasCustomImage = tokens.logoUrl

  if (!hasCustomBackgroundColor && !hasCustomImage) return {}

  return {
    ...(hasCustomImage && {
      wrap: {
        height: '100%',
        marginInlineStart: '-24px',
        width: '220px',
      },
    }),

    ...((hasCustomBackgroundColor || hasCustomImage) && {
      link: {
        ...(hasCustomBackgroundColor && {
          color: tokens.headerFillColor,

          ...CreateFocusCss({ outlineColor: tokens.headerFillColor! }),
        }),

        ...(hasCustomImage && {
          ...(!hasCustomBackgroundColor && {
            backgroundColor: '#ffffff',
          }),
          ...(tokens.showLogoOnWhite && { backgroundColor: '#ffffff' }),
          backgroundImage: `url(${tokens.logoUrl})`,
          backgroundOrigin: 'content-box',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          display: 'block',
          height: '100%',
          margin: 0,
          padding: '8px 24px',
          width: '100%',
        }),
      },
    }),

    ...(tokens.headerFillColor && {
      image: {
        svg: {
          fill: tokens.headerFillColor,
        },
      },

      text: {
        borderColor: hexa(tokens.headerFillColor, 0.3),
        color: tokens.headerFillColor,
      },
    }),
  } as const
}
